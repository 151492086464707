import React from 'react';
import * as s from './ListHighlightedBlocks.module.css';

const ListHighlightedBlocks = (props) => {
  const {
    children,
  } = props;

  return (
    <div className={s.root}>
      {children}
    </div>
  );
};

export default ListHighlightedBlocks;
