import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { useTimeoutFn, useCopyToClipboard } from 'react-use';
import copyIco from 'utils/images/ico-copy-original.svg';
import okIco from 'utils/images/ico-ok.svg';
import CopyIcon from 'components/icons/CopyIcon';
import OkIcon from 'components/icons/OkIcon';
import { gtmCustomEvent } from 'utils/analytics';
import cx from 'classnames';
import * as s from './CopyWithIcon.module.css';

const RESET_TIMEOUT = 1000;

const CopyWithIcon = (props) => {
  const { 
    text,
    className,
    onCopy,
    onCopyReset,
    iconType,
    toggleIcon,
  } = props;

  const [copied, setCopied] = useState(false);
  const [state, copyToClipboard] = useCopyToClipboard();

  const resetStatus = () => {
    setCopied(false);
    onCopyReset && onCopyReset();
  }

  const [isReady, cancel, reset] = useTimeoutFn(resetStatus, RESET_TIMEOUT);

  const isColored = iconType === 'color';

  const handleCopy = () => {
    copyToClipboard(text);
    reset();
    setCopied(true);
    onCopy && onCopy();
    gtmCustomEvent({
      category: 'site_blog',
      action: 'copy_wallet_click',
      label: JSON.stringify({ button: text }),
    });
  }

  return (
    <button className={cx(s.root, {
      [s.colored]: isColored,
    })} onClick={handleCopy} title="Copy">
      {
        isColored
          ? (copied && toggleIcon) ? <OkIcon /> : <CopyIcon />
          : (copied && toggleIcon) ? <img src={okIco} alt="copied" /> : <img src={copyIco} alt="copy" />
      }
    </button>
  );
}

CopyWithIcon.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  iconType: PropTypes.string,
  onCopy: PropTypes.func,
  onCopyReset: PropTypes.func,
  toggleIcon: PropTypes.bool,
};

CopyWithIcon.defaultProps = {
  text: '',
  className: '',
  iconType: 'white',
  toggleIcon: true,
};

export default CopyWithIcon;
