import _get from 'lodash/get';

export const preparePathWithSlash = (path = '') => {
  const isPathAlreadyWithSlash = path.slice(-1) === '/';
  const isPathWithHash = path.includes('#');
  if (isPathAlreadyWithSlash || isPathWithHash) {
    return path;
  }
  return `${path}/`;
}

export const checkIsSafari = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const userAgentString = _get(window, 'navigator.userAgent');
  // Detect Chrome 
  const chromeAgent = userAgentString.indexOf("Chrome") > -1;
  // Detect Safari 
  let safariAgent = userAgentString.indexOf("Safari") > -1; 
    
  // Discard Safari since it also matches Chrome 
  if (chromeAgent && safariAgent) {
    safariAgent = false;
  }
  return safariAgent;

}

export const getIntlUrl = (urlObj, locale, baseUrl = '') => {
  const { url, urlsIntl = {} } = urlObj;
  if (locale === 'en') { return `${baseUrl}${url}`; }
  return `${baseUrl}${urlsIntl[locale] || url}`;
}