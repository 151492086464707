import React from "react";
import * as s from './CopyIcon.module.css';

const CopyIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={s.root}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 22h13v-4h5V2H8v4H3v16ZM9 6V3h11v14h-4V6H9ZM4 21V7h11v14H4Z"
      fill="url(#CopyIcon)"
    />
    <defs>
      <linearGradient
        id="CopyIcon"
        x1={22.027}
        y1={9.54}
        x2={-0.042}
        y2={18.255}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#017EF4" />
        <stop offset={1} stopColor="#B769EB" />
      </linearGradient>
    </defs>
  </svg>
)

export default CopyIcon;
