import React from 'react';
import PropTypes from 'prop-types';
import * as s from './orange.module.css';

const Orange = ({ children }) => (
  <div className={s.orange}>{children}</div>
);

Orange.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

export default Orange;
