import React from 'react';
import PropTypes from 'prop-types';

const TextColorBlock = ({ children, color }) => (
  <div style={{ color }}>{children}</div>
);

TextColorBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  color: PropTypes.string,
};

TextColorBlock.defaultProps = {
  color: '#fff',
}

export default TextColorBlock;
