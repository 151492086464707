import React from 'react';

const useBrowserDetect = () => {
	const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
	// Link to more regexp https://github.com/DamonOehlman/detect-browser/blob/master/src/index.ts
	const isSafari = /Version\/([0-9._]+).*Safari/.test(userAgent);
	return {
		isSafari,
	};
};

export default useBrowserDetect;
