import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as s from './MediaBlock.module.css';

const MediaBlock = (props) => {
  const {
    children,
    styles,
    title,
    image,
    noIndent,
    hasPadding,
    imgSize = 100,
  } = props;

  return (
    <div className={s.root} style={{ ...styles }}>
      <div className={cx(s.image, {
        [s.noIndent]: noIndent,
        [s.hasPadding]: hasPadding,
      })} style={{width: `${imgSize}px`, minWidth: `${imgSize}px`}}><img src={image} alt="icon"/></div>
      <div className={s.container}>
        {
          title ? (
          <div className={s.title}>
            {title}
          </div>
          ) : null
        }
        <div className={s.content}>{children}</div>
      </div>
    </div>
  );
};

MediaBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  title: PropTypes.string,
};

export default MediaBlock;
