import React from "react";
import PropTypes from "prop-types";

const IconTelegram = (props) => {
  const { className } = props;
  return (
    <svg className={className} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="icon-blue-gradient" x1="27.8902" y1="-7.14438" x2="-0.0835929" y2="32.4093" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7D8AFE"/>
        <stop offset="1" stopColor="#C4C4C4"/>
        </linearGradient>
      </defs>
      <path d="M18.65 0.72357L0.933877 7.55523C-0.275178 8.04085 -0.268184 8.71532 0.71205 9.01609L5.2605 10.435L15.7843 3.79517C16.2819 3.49241 16.7365 3.65528 16.3628 3.98702L7.83648 11.682H7.83448L7.83648 11.683L7.52273 16.3713C7.98237 16.3713 8.18521 16.1605 8.44301 15.9117L10.6523 13.7634L15.2477 17.1577C16.095 17.6244 16.7036 17.3845 16.9144 16.3733L19.931 2.15645C20.2398 0.918418 19.4584 0.357856 18.65 0.72357Z"/>
    </svg>
  );
};

IconTelegram.propTypes = {
  className: PropTypes.string,
};

IconTelegram.defaultProps = {
  className: '',
}

export default IconTelegram;