import logo from 'utils/images/hiveos-logo.svg'
import logoAsic from 'utils/images/hiveonasic-header.svg';
import bigminerLogo from 'utils/images/bigminer/bigminerLogo.svg'
import bigminerLogoAsic from 'utils/images/bigminer/bigminerLogoSmall.svg'
import bigminerSmallLogo from 'utils/images/bigminer/bigminerLogoSmall.svg'
import bigminerQr from 'utils/images/bigminer/bigminer-qr.png'
import bigminerQrPricing from 'utils/images/bigminer/bigminer-qr-pricing.svg'

import messages_en from 'locales/en.json';
import messages_en_bigminer from 'locales/en_bigminer.json';
import messages_ru from 'locales/ru.json';
import messages_zh from 'locales/zh.json';
import messages_cn from 'locales/zh_bigminer.json';

import modules_messages_en from '@hiveon/modules/ui-kit/i18n/en.json';
import modules_messages_ru from '@hiveon/modules/ui-kit/i18n/ru.json';
import modules_messages_zh from '@hiveon/modules/ui-kit/i18n/zh.json';


import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';

export const WL_BIGMINER = 'bigminer';
export const WL_HIVEOS = 'hiveos';

const hiveDescriptor = {
  key: 'hiveos',
  name: 'Hive OS',
  hasHeader: true,
  logo: logo,
  logoWidth: 133,
  logoHeight: 36,
  smalllogo: logo,
  smallLogoWidth: 179,
  smallLogoHeight: 41,
  logoAsic: logoAsic,
  logoAsicWidth: 198,
  logoAsicHeight: 55,
  rigImage: 'rig_hive.png',
  regLinkTarget: '_self',
  referralBonus: 10,

  langs: ['en', 'ru', 'zh'],
  defaultLang: 'en',
  checkBrowserLang: true,
  messages: {
    en: {...messages_en, ...modules_messages_en},
    ru: {...messages_ru, ...modules_messages_ru},
    zh: {...messages_zh, ...modules_messages_zh},
  },

  loginLink: 'https://the.hiveos.farm/login/',
  registerLink: 'https://the.hiveos.farm/register/',
  referralLink: 'https://the.hiveos.farm/referrals/',
  downloadLatestTorrentLink: 'https://download.hiveos.farm/latest/?torrent',
  downloadLatestZipLink: 'https://download.hiveos.farm/latest/',
  howItWorks: {
    en: 'F5bAsKGCKTE',
    ru: 'y0tnm9lT_7c',
    zh: 'F5bAsKGCKTE',
  },
  supportEmail: 'bee@hiveos.farm',
  businessEmail: 'brain@hiveos.farm',
  twitter: 'https://twitter.com/hiveonofficial',
  facebook: 'https://www.facebook.com/hiveon/',
  telegram: 'https://t.me/hiveon_official',
  youtube: 'https://www.youtube.com/HiveOS',
  github: 'https://github.com/minershive',
  forum: 'https://hiveon.com/forum/',
  status: 'https://status.hiveos.farm/',
  etcherLink: 'https://www.balena.io/etcher/',
  liksWithLocale: ['https://the.hiveos.farm/login/', 'https://the.hiveos.farm/register/'],

  hasApp: true,
  hasKb: true,
  hasCommunity: true,
  hasForum: true,
  hasApi: true,
  hasStatistics: true,
  hasAsics: true,
  hasAhub: true,
  hasBeta: true,
  hasVega: true,
  hasIntercom: true,
  hasTestimonials: true,
  showLangFlags: true,
  showHexImg: true,
  showTelegram: true,
  showDiscord: true,
  showApi: true,
  showWatchdog: true,
  salesBtnType: 'button',
  showForumLinks: true,
  showGetStarted: true,
  showAsicGuide: true,
  showCookieBar: true,
  withLinkCanonical: true,
  withLinkHrefLang: true,

  topNav: [
    {
      title: {main: 'Hive', sub: 'OS'},
      subtitle: 'TopNav.home',
      link: '/',
      isExternal: false,
      withLang: false,
    },
    {
      title: {main: 'Hiveon', sub: 'Pool'},
      subtitle: 'TopNav.pool',
      link: 'https://multisite.hiveon.dev/pool',
      isExternal: true,
      withLang: true,
    },
    {
      title: {main: 'Hiveon', sub: 'ASIC'},
      subtitle: 'TopNav.asic',
      link: '/asic',
      isExternal: false,
      withLang: false,
    },
    {
      title: {main: 'Hiveon', sub: 'ASIC Hub'},
      subtitle: 'TopNav.asicHub',
      link: '/asichub',
      isExternal: false,
      withLang: false,
    },
    {
      title: {main: 'Hiveon', sub: 'Community'},
      subtitle: 'TopNav.kb',
      link: 'https://hiveon.com/forum',
      isExternal: true,
      withLang: false,
    },
  ],
};

const bigminerDescriptor = {
  key: 'bigminer',
  name: 'Bigminer',
  logo: bigminerLogo,
  logoWidth: 159,
  logoHeight: 35,
  smalllogo: bigminerSmallLogo,
  smallLogoWidth: 135,
  smallLogoHeight: 30,
  logoAsic: bigminerLogoAsic,
  logoAsicWidth: 135,
  logoAsicHeight: 30,
  rigImage: 'rig_bigminer.png',
  regLinkTarget: '_blank',

  langs: ['en', 'zh'],
  defaultLang: 'zh',
  checkBrowserLang: false,
  messages: {
    en: messages_en_bigminer,
    zh: messages_cn,
  },

  loginLink: 'http://the.bigminer.io/login/',
  registerLink: 'http://the.bigminer.io/register/',
  etcherLink: 'https://download.bigminer.io/Etcher-Portable-1.4.9-x64.exe',
  supportEmail: 'support@bigminer.farm',
  businessEmail: 'brain@bigminer.farm',
  hasMenuHome: true,
  hasAsics: true,
  salesBtnType: 'qr',
  salesBtnQr: bigminerQr,
  pricingBtnQr: bigminerQrPricing,
  hasGuides: true,
  liksWithLocale: [],

  topNav: [
    {
      title: {main: 'Bigminer'},
      subtitle: 'TopNav.home',
      link: '/',
      isExternal: false,
    },
    {
      title: {main: 'Bigminer', sub: 'ASIC'},
      subtitle: 'TopNav.asic',
      link: '/asic',
      isExternal: false,
    },
  ],

  footer: {
    text: 'Beijing ICP No. 2021001233',
    href: 'https://beian.miit.gov.cn',
  },
};

const whiteLabelDescriptors = {
  bigminer: bigminerDescriptor,
};

const descr = whiteLabelDescriptors[process.env.GATSBY_WHITE_LABEL] || hiveDescriptor;

export const WL = {
  descriptor: () => descr,
  isHeaderShown: () => descr.hasHeader,

  getName: () => descr.name,
  getLogo: () => descr.logo,
  getLogoWidth: () => descr.logoWidth,
  getLogoHeight: () => descr.logoHeight,
  getLogoAsic: () => descr.logoAsic,
  getLogoAsicWidth: () => descr.logoAsicWidth,
  getLogoAsicHeight: () => descr.logoAsicHeight,
  getSmallLogo: () => descr.smalllogo,
  getSmallLogoWidth: () => descr.smallLogoWidth,
  getSmallLogoHeight: () => descr.smallLogoHeight,
  getRigImage: () => descr.rigImage,
  getLangs: () => descr.langs,
  getMessages: () => descr.messages,
  getLoginLink: () => descr.loginLink,
  getRegisterLink: () => descr.registerLink,
  getDownloadLatestTorrentLink: () => descr.downloadLatestTorrentLink,
  getDownloadLatestZipLink: () => descr.downloadLatestZipLink,
  getHowItWorks: (lang = 'en', keyOnly = false) => {
    const key = _get(descr, ['howItWorks', lang]);

    return keyOnly ? key : `https://www.youtube.com/watch?v=${key}`;
  },
  getSupportEmail: () => descr.supportEmail,
  getBusinessEmail: () => descr.businessEmail,
  getTwitter: () => descr.twitter,
  getFacebook: () => descr.facebook,
  getTelegram: () => descr.telegram,
  getYoutube: () => descr.youtube,
  getGithub: () => descr.github,
  getForum: () => descr.forum,
  getStatus: () => descr.status,

  hasApp: () => descr.hasApp,
  hasKb: () => descr.hasKb,
  hasCommunity: () => descr.hasCommunity,
  hasApi: () => descr.hasApi,
  hasStatistics: () => descr.hasStatistics,
  hasAsics: () => descr.hasAsics,
  hasAhub: () => descr.hasAhub,
  hasBeta: () => descr.hasBeta,
  hasVega: () => descr.hasVega,
  hasIntercom: () => descr.hasIntercom,
  hasTestimonials: () => descr.hasTestimonials,
  hasGuides: () => descr.hasGuides,
  hasMenuHome: descr.hasMenuHome,
  showLangFlags: descr.showLangFlags,
  showHexImg: descr.showHexImg,
  showTelegram: descr.showTelegram,
  showDiscord: descr.showDiscord,
  showApi: descr.showApi,
  showWatchdog: descr.showWatchdog,
  salesBtnType: descr.salesBtnType,
  salesBtnQr: descr.salesBtnQr,
  pricingBtnQr: descr.pricingBtnQr,
  showForumLinks: descr.showForumLinks,
  topNav: descr.topNav,
  footer: descr.footer,
  showGetStarted: descr.showGetStarted,
  key: descr.key,
  showCookieBar: descr.showCookieBar,
  showAsicGuide: descr.showAsicGuide,
  defaultLang: descr.defaultLang,
  checkBrowserLang: descr.checkBrowserLang,
  etcherLink: descr.etcherLink,
  liksWithLocale: descr.liksWithLocale,
  withLinkCanonical: descr.withLinkCanonical,
  regLinkTarget: descr.regLinkTarget,
  referralLink: descr.referralLink,
  referralBonus: descr.referralBonus,
  withLinkHrefLang: descr.withLinkHrefLang,

  has: item => _get(descr, `has${_upperFirst(item)}`, false)
};

export default WL;
