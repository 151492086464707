import TextColorBlock from "../../../../src/components/mdx/TextColorBlock";
import Orange from "../../../../src/components/mdx/Orange";
import Col2 from "../../../../src/components/mdx/Col2";
import CollapseBlock from "../../../../src/components/mdx/CollapseBlock";
import MediaBlock from "../../../../src/components/mdx/MediaBlock";
import TitleH2 from "../../../../src/components/mdx/TitleH2";
import TitleH3 from "../../../../src/components/mdx/TitleH3";
import HighlightedBlock from "../../../../src/components/mdx/HighlightedBlock";
import BlockNumberList from "../../../../src/components/mdx/BlockNumberList";
import LabeledTextBlock from "../../../../src/components/mdx/LabeledTextBlock";
import ListHighlightedBlocks from "../../../../src/components/mdx/ListHighlightedBlocks";
import Warning from "../../../../src/components/mdx/Warning";
import * as React from 'react';
export default {
  TextColorBlock,
  Orange,
  Col2,
  CollapseBlock,
  MediaBlock,
  TitleH2,
  TitleH3,
  HighlightedBlock,
  BlockNumberList,
  LabeledTextBlock,
  ListHighlightedBlocks,
  Warning,
  React
};