import React, { useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import arrowCollapseImg from 'utils/images/arrow_collapse.png';
import iconSpeech from 'utils/images/icon-speech.svg';
import iconRocket from 'utils/images/icon-rocket.svg';
import iconTarget from 'utils/images/icon-target.svg';
import iconWallet from 'utils/images/icon-wallet.svg';
import iconMine from 'utils/images/icon-mine.svg';
import iconIdea from 'utils/images/icon-idea.svg';
import iconCalc from 'utils/images/icon-calc.svg';
import cx from 'classnames';
import * as s from './CollapseBlock.module.css';
import _snakeCase from 'lodash/snakeCase.js';

const iconsMap = {
  'speech': iconSpeech,
  'rocket': iconRocket,
  'target': iconTarget,
  'wallet': iconWallet,
  'mine': iconMine,
  'calc': iconCalc,
  'idea': iconIdea,
}

const CollapseBlock = (props) => {
  const {
    children,
    styles,
    title,
    forceOpen = false,
    isDefaultOpen = false,
    titleIcon = '',
  } = props;

  const [height, setHeight] = useState(0);
  const [isOpen, setIsOpen] = useState(() => isDefaultOpen);
  const contentRef = useRef(null);
  const isCollapseOpen = forceOpen ? true : isOpen;

  const containerStyle = {
    height:  `${isCollapseOpen ? height : 0}px`,
  };

  useLayoutEffect(() => {
    if (contentRef && contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
    }
  }, [isOpen]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={s.root} style={{ ...styles }}>
      <h2
        className={s.title}
        onClick={toggleOpen}
        role="presentation"
      >
        <span className={cx(s.titleText, {
          [s.hasIcon]: !!(titleIcon && iconsMap[titleIcon]),
        })}>
          {titleIcon && iconsMap[titleIcon] ? (<img className={s.titleIcon} src={iconsMap[titleIcon]} alt="icon"/>) : null}
          <span>{title}</span>

          <img src={arrowCollapseImg} className={cx(s.arrow, {
            [s.isOpen]: isCollapseOpen,
          })} alt="arrow" />
        </span>
      </h2>

      <div
        ref={contentRef}
        className={s.container}
        style={forceOpen ? {} : containerStyle}
      >
        <div data-gtm-block={_snakeCase(title)} className={s.content}>{children}</div>
      </div>
    </div>
  );
};

CollapseBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  title: PropTypes.string,
};

export default CollapseBlock;
