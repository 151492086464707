import TitleH2 from "../../../../src/components/mdx/TitleH2";
import TitleH3 from "../../../../src/components/mdx/TitleH3";
import CollapseBlock from "../../../../src/components/mdx/CollapseBlock";
import TextColorBlock from "../../../../src/components/mdx/TextColorBlock";
import Orange from "../../../../src/components/mdx/Orange";
import HighlightedBlock from "../../../../src/components/mdx/HighlightedBlock";
import * as React from 'react';
export default {
  TitleH2,
  TitleH3,
  CollapseBlock,
  TextColorBlock,
  Orange,
  HighlightedBlock,
  React
};