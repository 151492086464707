import React from 'react';
import * as s from './BlockNumberList.module.css';

const BlockNumberList = (props) => {
  const {
    children,
  } = props;

  return (
    <div className={s.root}>
      {children}
    </div>
  );
};

export default BlockNumberList;
