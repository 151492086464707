import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage as L } from 'react-intl';
import _get from 'lodash/get';
import _some from 'lodash/some';
import Link from 'components/Link';
import Cookies from 'js-cookie';
import withLocation from 'helpers/withLocation';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { IconClose } from 'components/common/Icons';
import { toggleCookieBar as toggleCookieBarAction, toggleCookieGa as toggleCookieGaAction } from 'actions/index';
import { 
  GDPR_GA,
  GDPR_TGM,
  GDPR_FB,
  GDPR_HJ,
  isSomeGdprNotSet,
  isAllGdprNotSet,
  sendAnonymusBannerStat,
} from 'constants/cookieGdpr';
import WL from 'constants/whiteLabel';
import Close from 'utils/images/close.svg';
import * as s from './cookieBar.module.scss';

class CookieBar extends Component {
  constructor(props) {
    super(props);

    this.refInput = React.createRef();
  }

  componentDidMount() {
    this.props.toggleCookieBar(isSomeGdprNotSet());
    isSomeGdprNotSet() && sendAnonymusBannerStat('site_consent', 'popup_shown', JSON.stringify({'site': 'os'}));
  }

  handleAccept() {
    const { location } = this.props;

    const current = !_some(
      [GDPR_GA, GDPR_TGM, GDPR_FB, GDPR_HJ],
      item => Cookies.get(item) === 'false'
    );

    const checked = !!_get(this.refInput, 'current.checked', current);

    Cookies.set(GDPR_GA, checked, { expires: 3650 });
    Cookies.set(GDPR_TGM, checked, { expires: 3650 });
    Cookies.set(GDPR_FB, checked, { expires: 3650 });
    Cookies.set(GDPR_HJ, checked, { expires: 3650 });
    
    this.props.toggleCookieBar(false);
    this.props.toggleCookieGa(checked);

    checked && initializeAndTrack(location);
    sendAnonymusBannerStat('site_consent', 'popup_click', JSON.stringify({'site': 'os', 'button': 'ok', 'statistics': checked ? 'on' : 'off'}));
  }

  handleClose = () => {
    this.props.toggleCookieBar(false);
    sendAnonymusBannerStat('site_consent', 'popup_close_click', JSON.stringify({'site': 'os'}));
  }

  render() {
    const {
      isCookieBarOpen,
    } = this.props;

    if (!WL.showCookieBar || !isCookieBarOpen) {
      return null;
    }

    if (isAllGdprNotSet()) {
      return (
        <div className={s.wrapper}>
          <div className={s.container}>
            <button 
              className={s.close}
              onClick={this.handleClose}
            >
              <IconClose/>
            </button>

            <div className={s.cookieIcon}>
              <span className={s.cookieLabel}>
                <L id="terms.cookies.title"/>
              </span>
            </div>
            
            <div className={s.root}>
              <div>
                <div className={s.description}>
                  <L
                    id="cookieBar.text1"
                    defaultMessage="Please note! We use necessary and analytics cookies to improve the performance of our services and to understand how you interact with our website — learn more, please see our"
                  />&nbsp;
                  <Link
                    external
                    withLang
                    to="https://hiveon.com/privacy"
                    className={s.underlined}
                  >
                    <L id="cookieBar.link" defaultMessage="Cookie Policy" />
                  </Link>.&nbsp;
                  <L
                    id="cookieBar.text2"
                    values={{
                      ok: <L id="cookieBar.ok" defaultMessage="OK" />
                    }}
                    defaultMessage="To accept cookies, click OK."
                  />
                </div>
                <div className={s.row}>
                  <div className={s.checkboxBlock}>
                    <div>
                      <label htmlFor="necessary">
                        <input
                          type="checkbox"
                          id="necessary_cookie"
                          name="necessary"
                          checked
                          disabled={true}
                          className={s.customCheckbox}
                        />
                        <span className={s.labelCheckbox}>
                          <L id="cookieBar.necessary" defaultMessage="Necessary" />
                        </span>
                      </label>
                    </div>
                    <div>
                      <label htmlFor="statistics_cookie">
                        <input
                          type="checkbox"
                          id="statistics_cookie"
                          name="statistics"
                          ref={this.refInput}
                          defaultChecked
                          className={s.customCheckbox}
                        />
                        <span className={s.labelCheckbox}>
                          <L id="cookieBar.statistics" defaultMessage="Statistics" />
                        </span>
                      </label>
                    </div>
                  </div>
                  
                  <button
                    className={s.btn}
                    onClick={() => this.handleAccept()}
                  >
                    <L id="cookieBar.ok" defaultMessage="OK" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (isSomeGdprNotSet()) {
      return (
        <div className={s.wrapper}>
          <div className={s.narrowContainer}>
            <button 
              className={s.close}
              onClick={() => this.props.toggleCookieBar(false)}
            >
              <IconClose/>
            </button>
            <div>
              <L id="cookieBar.text3"/>&nbsp;
              <Link
                external
                withLang
                to="https://hiveon.com/privacy"
                className={s.underlined}
              >
                <L id="cookieBar.link2" defaultMessage="Cookie Policy" />
              </Link>
            </div>
            <button
              className={s.btn}
              onClick={() => this.handleAccept()}
            >
              <L id="cookieBar.ok" defaultMessage="OK" />
            </button>
          </div>
        </div>
      );
    }

    return;
  }
}

CookieBar.propTypes = {
  toggleCookieBar: PropTypes.func.isRequired,
  isCookieBarOpen: PropTypes.bool,
};

const mapStateToProps = ({ root }) => ({
  isCookieBarOpen: root.isCookieBarOpen,
})

const mapDispatchToProps = {
  toggleCookieBar: toggleCookieBarAction,
  toggleCookieGa: toggleCookieGaAction,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocation(CookieBar));
