import React from 'react';
import PropTypes from 'prop-types';

const getMargin = ({ top, right, bottom, left }) => ({
  margin: `${top}px ${right}px ${bottom}px ${left}px`
})

const MarginBlock = ({ children, top, right, bottom, left }) => (
  <div style={{ ...getMargin({ top, right, bottom, left }) }}>
    {children}
  </div>
);

MarginBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
};

MarginBlock.defaultProps = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}

export default MarginBlock;
