import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as s from './col2.module.css';

const Col2 = ({ children, cols='4' }) => (
  <div className={cx(s.col2, {
    [s.cols2]: cols === '2',
  })}>{children}</div>
);

Col2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

export default Col2;
