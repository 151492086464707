import Cookies from 'js-cookie';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

const tempClientId = uuidv4();

export const GDPR_GA = 'settings-gdpr-GA-accept';
export const GDPR_TGM = 'settings-gdpr-GTM-accept';
export const GDPR_FB = 'settings-gdpr-FB-accept';
export const GDPR_HJ = 'settings-gdpr-hotjar-accept';

export const isAllGdprNotSet = () =>
  !Cookies.get(GDPR_GA)
  && !Cookies.get(GDPR_TGM)
  && !Cookies.get(GDPR_FB)
  && !Cookies.get(GDPR_HJ);

export const isSomeGdprNotSet = () =>
  !(Cookies.get(GDPR_GA)
  && Cookies.get(GDPR_TGM)
  && Cookies.get(GDPR_FB)
  && Cookies.get(GDPR_HJ));

// This is manual event sending. It's not using any native ga libs to avoid cookies set and personal data embedding
export const sendAnonymusBannerStat = (category = '', action = '', label = '') => {
  const params = queryString.stringify({
    v: 1,
    tid: 'UA-6395920-15',
    cid: tempClientId,
    t: 'event',
    ec: category,
    ea: action,
    el: label,
    aip: 1,
    geoid: 'notset',
  });
  fetch(`https://www.google-analytics.com/collect?${params}`);
}