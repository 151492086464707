import React from 'react';
import * as s from './LabeledTextBlock.module.css';
import _snakeCase from 'lodash/snakeCase.js';

const LabeledTextBlock = (props) => {
  const {
    children,
    title,
    subtitle,
  } = props;

  return (
    <div className={s.root}>
      <div className={s.label}>
        <div className={s.title}>{title}</div>
        <div className={s.subtitle}>{subtitle}</div>
      </div>
      <div className={s.text} data-gtm-block={_snakeCase(subtitle)}>
        {children}
      </div>
    </div>
  );
};

export default LabeledTextBlock;
