import TextColorBlock from "../../../../src/components/mdx/TextColorBlock";
import CollapseBlock from "../../../../src/components/mdx/CollapseBlock";
import TitleH2 from "../../../../src/components/mdx/TitleH2";
import TitleH3 from "../../../../src/components/mdx/TitleH3";
import HighlightedBlock from "../../../../src/components/mdx/HighlightedBlock";
import MarginBlock from "../../../../src/components/mdx/MarginBlock";
import LabeledTextBlock from "../../../../src/components/mdx/LabeledTextBlock";
import * as React from 'react';
export default {
  TextColorBlock,
  CollapseBlock,
  TitleH2,
  TitleH3,
  HighlightedBlock,
  MarginBlock,
  LabeledTextBlock,
  React
};