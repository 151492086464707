import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as s from './highlightedBlock.module.css';

const HighlightedBlock = ({ children, type="light", margins="normal" }) => (
  <div className={cx(s.highlight, {
    [s.dark]: type === 'dark',
    [s.marginsSmall]: margins === 'small',
  })}>
    {children}
  </div>
);

HighlightedBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

export default HighlightedBlock;
