import React from "react";
import PropTypes from "prop-types";

const IconClose = (props) => {
  const { className } = props;
  return (
    <svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L7 7M7 7L1 13M7 7L13 1M7 7L13 13" stroke="white" strokeOpacity="0.4" strokeWidth="2"/>
    </svg>
  );
};

IconClose.propTypes = {
  className: PropTypes.string,
};

IconClose.defaultProps = {
  className: '',
};

export default IconClose;