import React from 'react';
import _get from 'lodash/get';
import _trimEnd from 'lodash/trimEnd';
import _trim from 'lodash/trim';
import _last from 'lodash/last';
import { Link as GatsbyLink } from 'gatsby';
import { useIntl } from 'react-intl';
import languages from 'constants/languages';
import { preparePathWithSlash } from 'helpers/index';
import _omit from 'lodash/omit';
import _includes from 'lodash/includes'
import WL from 'constants/whiteLabel';
import useBrowserDetect from 'hooks/useBrowserDetect';

const langList = Object.values(languages);

const preparePathWithQuery = (lang, path) => {
  return `/${lang}${path}`;
};

const preparePathWithHash = (lang, path) => {
  return `/${lang}${path}`;
};

const prepareLinkLangPath = (lang, pathname, options = {}) => {
  const externalRoot = _get(options, 'externalRoot');
  const excludeLangs = _get(options, 'excludeLangs', []);

  if (
    lang === languages.EN ||
    !pathname ||
    _includes(excludeLangs, lang)
  ) {
    return pathname;
  }

  if (pathname.includes('?')) {
    return preparePathWithQuery(lang, pathname)
  }

  if (pathname.includes('#')) {
    return preparePathWithHash(lang, pathname)
  }

  if (pathname === '/') {
    return `${pathname}${lang}/`
  }

  if (externalRoot) {
    return `${_trimEnd(pathname, '/')}/${lang}`
  }

  return `/${lang}${pathname}`;
};

const Link = ({ to = '', href, withLang, children, external, externalRoot, shadowElement, externalSelf, targetDomainType, email, ...props }) => {
  const intl = useIntl();
  const { isSafari } = useBrowserDetect();
  const excludeLangs = _get(props, 'excludeLangs', []);
  const isHive = process.env.GATSBY_WHITE_LABEL !== 'bigminer';

  const hashChunks = to.split('#');
  const hashValue = hashChunks.length > 1 ? `#${_last(hashChunks)}` : '';
  const cleanUrl = _trim(_get(hashChunks, '0', '').replace(/[#]/g, ''), '/');
  const urlChunks = cleanUrl.split('_');
  const langFromUrl = _last(urlChunks);
  const hasLangInUrl = langList.includes(langFromUrl);
  const cleanTo = hasLangInUrl ? urlChunks.slice(0, -1).join('_') : urlChunks.join('_');

  const getShouldWithLang = () => {
    if (withLang || hasLangInUrl) {
      return true;
    }

    return false;
  }

  const preparedLinkPath = getShouldWithLang() ?
  preparePathWithSlash(prepareLinkLangPath(intl.locale, `/${cleanTo}${hashValue}`)) :
  preparePathWithSlash(to);

  let externalHref = href || to;

  if (external && WL.liksWithLocale.includes(href)) {
    externalHref = `${href}?lang=${intl.locale}`;
  }

  if (external && withLang) {
    externalHref = prepareLinkLangPath(intl.locale, externalHref, { externalRoot, excludeLangs });
  }

  if (external) {
    return <a {...(externalSelf ? {} : {target: "_blank"})} rel="noreferrer" {..._omit(props, ['noRef'])} href={externalHref}>
      <>
        {children}
      </>
    </a>
  }

  return <GatsbyLink to={preparedLinkPath} {...props}>
    <>
      {children}
    </>
  </GatsbyLink>
};

export default Link;

