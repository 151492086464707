import React from 'react';
import PropTypes from 'prop-types';
import iconWarning from 'utils/images/icon_warning.svg';
import * as s from './Warning.module.css';

const Warning = (props) => {
  const {
    children,
  } = props;

  return (
    <div className={s.root}>
      <div className={s.icon}><img src={iconWarning} alt="icon"/></div>
      <div className={s.content}>{children}</div>
    </div>
  );
};

Warning.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

export default Warning;
