import React from 'react';
import PropTypes from 'prop-types';
import * as s from './orangeCommand.module.css';

const OrangeCommand = ({ children }) => (
  <div className={s.orangeCommand}>{children}</div>
);

OrangeCommand.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

export default OrangeCommand;
