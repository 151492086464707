import React from 'react';
import PropTypes from 'prop-types';
import * as s from './TitleH2.module.css';

const TitleH2 = (props) => {
  const {
    children,
    styles,
  } = props;

  return (
    <h2 className={s.root} style={{ ...styles }}>
      {children}
    </h2>
  );
};

TitleH2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  title: PropTypes.string,
};

export default TitleH2;
