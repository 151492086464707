import React from "react";
import * as s from './OkIcon.module.css';

const OkIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={s.root}
    {...props}
  >
    <path
      d="m2.606 14.219 6.75 6.363a1 1 0 0 0 1.53-.194L21.889 2.986"
      stroke="url(#OkIcon)"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="OkIcon"
        x1={22.988}
        y1={9.934}
        x2={0.417}
        y2={20.296}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#017EF4" />
        <stop offset={1} stopColor="#B769EB" />
      </linearGradient>
    </defs>
  </svg>
)

export default OkIcon;
