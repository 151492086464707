import Cookies from 'js-cookie';
import { GDPR_GA } from 'constants/cookieGdpr';

export const trackDownloadFirmware = () => {
  if (!!Cookies.get(GDPR_GA) && window.ga) {
    window.ga('send', 'event', 'download_firmware', 'download_firmware_button', {
      transport: 'beacon',
    });
  }
};

export const gtmCustomEvent = (dataLayerObject) => {
  const {
    category,
    action,
    label = '',
    value = '',
  } = dataLayerObject;
  if (!category || !action) { return; }
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push({
      'event' : 'GAEvent',
      'eventCategory' : category,
      'eventAction' : action,
      'eventLabel' : label,
      'eventValue' : value,
    });
  }
}
