import React from 'react';
import PropTypes from 'prop-types';
import markerImg from 'utils/images/article-marker.png';
import cx from 'classnames';
import * as s from './TitleH3.module.css';

const TitleH3 = (props) => {
  const {
    children,
    styles,
    isMarked,
  } = props;

  return (
    <h3 className={cx(s.root, {
      [s.isMarked]: isMarked,
    })} style={{ ...styles }}>
      { isMarked ? <img src={markerImg} alt="icon" /> : null }
      <span>{children}</span>
    </h3>
  );
};

TitleH3.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  title: PropTypes.string,
};

export default TitleH3;
