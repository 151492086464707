// extracted by mini-css-extract-plugin
export var wrapper = "cookieBar-module--wrapper--L7hLH";
export var cookieIcon = "cookieBar-module--cookieIcon--t139E";
export var cookieLabel = "cookieBar-module--cookieLabel--4lgiR";
export var container = "cookieBar-module--container--k1x+L";
export var narrowContainer = "cookieBar-module--narrowContainer--HvtzJ";
export var close = "cookieBar-module--close--TIpZI";
export var root = "cookieBar-module--root--+dhOC";
export var description = "cookieBar-module--description--RpgNg";
export var underlined = "cookieBar-module--underlined--ZkXfd";
export var row = "cookieBar-module--row--peYaY";
export var checkboxBlock = "cookieBar-module--checkboxBlock--QuWlt";
export var customCheckbox = "cookieBar-module--customCheckbox--gEXkQ";
export var labelCheckbox = "cookieBar-module--labelCheckbox--l3urA";
export var btn = "cookieBar-module--btn--JjjqG";